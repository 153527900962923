






















































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Game, GM } from '@/api'
import { ItemFactory } from '@/services/Factories'
import { formatNumber } from '@/services/functions'
import { SPLIT_COMMAS_REGEX } from '@/services/Validation/regexes'
import { DateManager } from '@/services/Managers'
import { FormHelper } from '@/mixins'

enum BazaarStatus {
  EXPIRED = 'Expired',
  ON_SALE = 'On Sale',
  COMPLETED = 'Completed'
}

enum BazaarSortOptions {
  ALPHA_UP,
  ALPHA_DOWN,
  PRICE_ASC,
  PRICE_DESC,
  EXPIRY_ASC,
  EXPIRY_DESC
}

@Component({
  components: {
    ItemIconCard: () => import('@/components/Item/ItemIconCard.vue')
  }
})
export default class CharacterBazaar extends Mixins(FormHelper) {
  initialData = []
  data = []
  tableColumns = [
    { key: 'itemInstance', label: 'Item Name' },
    { key: 'amount' },
    { key: 'pricePerItem', sortable: true },
    { key: 'status', sortable: true },
    { key: 'expiryDate', label: 'Expires in' }
  ]
  // Filters
  status = null
  itemName = null
  sortOption = null

  formatNumber = formatNumber
  SPLIT_COMMAS_REGEX = SPLIT_COMMAS_REGEX

  itemBazaarStatus = [
    BazaarStatus.COMPLETED,
    BazaarStatus.ON_SALE,
    BazaarStatus.EXPIRED
  ]

  itemBazaarSortOptions = [
    { title: 'Alphabetical (A-Z)', value: BazaarSortOptions.ALPHA_UP },
    { title: 'Alphabetical (Z-A)', value: BazaarSortOptions.ALPHA_DOWN },
    { title: 'Price (ascending)',  value: BazaarSortOptions.PRICE_ASC },
    { title: 'Price (descending)', value: BazaarSortOptions.PRICE_DESC },
    { title: 'Expiry Date (ascending)', value: BazaarSortOptions.EXPIRY_ASC },
    { title: 'Expiry Date (descending)', value: BazaarSortOptions.EXPIRY_DESC }
  ]

  @Watch('status')
  onItemBazaarStatusChanged(value) {
    this.data = value ? this.initialData.filter((e: any) => e.status == value) : this.initialData
  }

  @Watch('itemName')
  onItemNameChanged(value) {
    this.data = value ? this.initialData.filter((e: any) => e.itemInstance.name.toLowerCase().includes(value.toLowerCase())) : this.initialData
  }

  @Watch('sortOption')
  onSortOptionChanged(sortOption) {
    this.data = [...this.initialData]

    switch (sortOption?.value) {
      case BazaarSortOptions.ALPHA_UP:
        this.data.sort((a: any, b: any) => {
          return b.itemInstance.name > a.itemInstance.name ? -1 : a.itemInstance.name > b.itemInstance.name ? 1 : 0
        })
        break
      case BazaarSortOptions.ALPHA_DOWN:
        this.data.sort((a: any, b: any) => {
          return a.itemInstance.name > b.itemInstance.name ? -1 : b.itemInstance.name > a.itemInstance.name ? 1 : 0
        })
        break
      case BazaarSortOptions.PRICE_ASC:
        this.data.sort((a: any, b: any) => a.pricePerItem - b.pricePerItem)
        break
      case BazaarSortOptions.PRICE_DESC:
        this.data.sort((a: any, b: any) => b.pricePerItem - a.pricePerItem)
        break
      case BazaarSortOptions.EXPIRY_ASC:
        this.data.sort((a: any, b: any) => {
          return a.expiryDate.date > b.expiryDate.date ? -1 : b.expiryDate.date > a.expiryDate.date ? 1 : 0
        })
        break
      case BazaarSortOptions.EXPIRY_DESC:
        this.data.sort((a: any, b: any) => {
          return b.expiryDate.date > a.expiryDate.date ? -1 : a.expiryDate.date > b.expiryDate.date ? 1 : 0
        })
        break
      default:
        this.data = this.initialData
        break
    }
  }

  async mounted() {
    await this.getBazaarItems()
  }

  async getBazaarItems() {
    try {
      const { characterId } = this.$route.params
      const data = this.$route.path.includes('view')
        ? await GM.getCharacterBazaar(characterId)
        : await Game.getCharacterBazaar(characterId)

      if (!data) return

      const itemsIds = data.map(({ itemInstance }) => itemInstance.itemVNum)
      const names = await Game.getItemsNames(itemsIds)
      const icons = await Game.getItemsIcons(itemsIds)
      const itemData = names.map((item, i) => Object.assign({}, item, icons[i]))

      const buildItem = item => {
        const { name, iconId } = itemData.find(e => e.vnum == item.itemInstance.itemVNum)

        return new ItemFactory(item).buildItem({ name, iconId })
      }

      this.initialData = data.map((e, i) => {
        return {
          ...e,
          itemInstance: buildItem(e),
          status: this.getItemStatus(e),
          expiryDate: {
            date: new Date(e.expiryDate),
            remainingTime: DateManager.getRemainingTime(e.expiryDate)
          }
        }
      })
      this.data = this.initialData
    } catch(e) {
      console.error(`Error when fetching Character #${this.$route.params.characterId} NosBazaar`, e)
    }
  }

  popoverConfig(data) {
    const { vnum, name, rarity, type, upgrade } = data

    return {
      html: true,
      title: `${name} (${vnum})`,
      content: () => {
        return `
          Rarity: ${rarity.value} <br />
          Upgrade: ${upgrade} <br />
          Type: ${type} <br />
        `
      }
    }
  }

  isItemExpired(item) {
    return DateManager.compareDatesFromNow(item.expiryDate)
  }

  isItemSold(item) {
    return item.soldAmount === item.amount
  }

  getItemStatus(item) {
    if (this.isItemExpired(item)) return BazaarStatus.EXPIRED

    if (this.isItemSold(item)) return BazaarStatus.COMPLETED

    return BazaarStatus.ON_SALE
  }

  getItemVariant(item) {
    if (this.isItemExpired(item)) return 'danger'

    if (this.isItemSold(item)) return 'success'

    return 'warning'
  }
}
